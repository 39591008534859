"use strict";

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var controller = new ScrollMagic.Controller();
var scrollZoomUp = document.querySelectorAll(".js-scrollZoomUp");
scrollZoomUp.forEach(function (scrollZoomUp) {
  //tween作成
  //console.dir(scrollZoomUp);
  var tween = TweenMax.from(scrollZoomUp.children, 1.0, {
    ease: Power4.easeOut,
    scale: 1.1,
    opacity: 0
  }); //シーンを作成

  var scene = new ScrollMagic.Scene({
    triggerElement: scrollZoomUp,
    reverse: false //triggerHook: "onCenter"

  }).setTween(tween) //.addIndicators('scrollZoomUp')
  .addTo(controller);
});
var parallax = document.querySelectorAll(".js-parallax"); //const parallax = document.getElementsByClassName("js-parallax");

parallax.forEach(function (parallax) {
  //tween作成
  console.dir(parallax);
  var tween = TweenMax.fromTo(parallax.children, 2.0, {
    ease: Linear.easeNone,
    y: "20%"
  }, {
    ease: Linear.easeNone,
    //y:"-20%",
    y: "0%"
  }); //シーンを作成

  var scene = new ScrollMagic.Scene({
    triggerElement: parallax,
    duration: "150%",
    triggerHook: "onEnter"
  }).setTween(tween) //trigger a TweenMax tween
  //.addIndicators('parallax')
  .addTo(controller);
});
$(function () {
  $.ajax({
    type: 'GET',
    url: 'https://klonklonklon.com/apps/note/wp-json/wp/custom/common',
    dataType: 'json'
  }).done(function (json) {
    // console.dir(json);
    new Promise(function (resolve, reject) {
      setMV(json.topics, '.js-ajax__wp-pickup');
      setSnap(json.snaps, '.js-ajax__wp-snap', '.p-topSnap');
      resolve();
    }).then(function () {
      console.log('JSON処理完了[TOP]');
    }).catch(function () {
      console.error();
    });
  }).fail(function (json) {
    console.error('WordPressのJSONデータの取得に失敗しました。[TOP]');
  }); //スナップの一覧用DOMを生成して埋め込む

  function setSnap(array, targetEle, removeEle) {
    if (array.length > 0) {
      console.log('"SNAP" data is existing');
      var html = '';
      $.each(array, function (i, row) {
        // console.log(row);
        var title = row.title; //タイトル

        var url = row.url; //URL

        var date = row.date; //投稿日

        var image = row.image;
        var author_name = row.author_name;
        var author_avatar = row.author_avatar;
        var author_url = row.author_url;
        var shop_name = row.shop_name;
        html += '<li>\n';
        html += '<figure class="p-topSnap__list__visual"><time datetime="' + date + '"><a href="' + url + '"><img src="' + image + '" alt="' + title + '"></a></time></figure>\n';
        html += '<div class="p-topSnap__list__bio">\n';
        html += '<figure class="p-topSnap__list__bio__avatar"><a href="' + author_url + '">' + author_avatar + '</a></figure>\n';
        html += '<div class="p-topSnap__list__bio__block">\n'; // html += '<p class="p-topSnap__list__bio__block__shop"><a href="'+shop_url+'" class="c-fs__underline">'+shop_name+'</a></p>';

        html += '<p class="p-topSnap__list__bio__block__shop">' + shop_name + '</p>\n';
        html += '<p  class="p-topSnap__list__bio__block__name">' + author_name + '</p>\n';
        html += '</div>\n';
        html += '</li>\n';
      }); // console.log(html);

      if (targetEle != null) {
        $(targetEle).append(html);
      }

      ;
    } else {
      console.log('"SNAP" data is nothing');

      if (removeEle != null) {
        $(removeEle).remove();
      } else {
        $(targetEle).apeend('<li>該当する記事がありません。</li>');
      }

      ;
    }
  } //特集一覧用DOMを生成して埋め込む


  function setMV(array, targetEle, removeEle) {
    new Promise(function (resolve, reject) {
      if (array.length > 0) {
        console.log('"MV" data is existing'); //水平スライダーのナビ・ページャーのDOM要素

        var navHorizon = '<div class="swiper-navigation p-topic__slider__horizon__nav"><div class="swiper-button-prev">前へ</div><div class="swiper-pagination"></div><div class="swiper-button-next">次へ</div></div>';
        var catBox = []; //カテゴリー一覧を格納

        var _array = array.filter(function (cat) {
          //「NO CATEGORY」以外のカテゴリーに所属している物を格納
          if (cat.category != 'none') {
            catBox.push(cat.category);
            return cat.display.indexOf('mv') != -1 ? cat : false;
          }
        });

        catBox = Array.from(new Set(catBox.join(',').split(','))).filter(function (v) {
          return v;
        }); // catBox = catBox.reverse();//配列の順番を逆転する（現状wordpressの順番通りになる）

        $.each(catBox, function (i, c) {
          var li = '';
          var catName = c;
          $.each(_array, function (i, row) {
            // console.log(row);
            var title = row.title; //タイトル

            var link = row.url; //URL

            var date = row.date; //日付

            var img_normal = row.img_normal; //1000px×750px

            var img_pc = row.img_pc; //1920px×1080px

            var img_sp = row.img_sp; //1080px×1920px

            var img_square = row.img_square; //1080px×1080px

            var img_rectangle = row.img_rectangle; //460px×160px

            var desc = row.description;
            var display = row.display.length > 0 ? new RegExp(String(row.display.join('|'))) : null;
            var category = row.category.length > 0 ? new RegExp(String(row.category.join('|'))) : null;

            if (category.test(catName)) {
              var cont = '';
              cont += '<li class="swiper-slide p-topic__slider__horizon__slide">\n';
              cont += '<div class="block inner c-inner__1000 p-topic__slider__cont">\n';
              cont += '<h3 class="p-topic__slider__ttl">' + title + '</h3>\n';
              cont += '<div class="edit p-topic__slider__txt">' + desc + '</div>\n';
              cont += '<div class="button1 p-topic__slider__btn"><a href="' + link + '">more</a></div>\n';
              cont += '</div>\n';
              cont += '<picture class="image p-topic__slider__visual">\n';
              cont += '<source media="(max-width: 834px)" srcset="' + img_sp + ' 834w">\n';
              cont += '<source media="(min-width: 835px)" srcset="' + img_pc + ' 1920w">\n';
              cont += '<img src="' + img_pc + '" alt="' + title + '" loading="lazy">\n';
              cont += '</picture>\n';
              cont += '</li>\n';
              li += cont;
            }
          });
          var section = document.createElement('section');
          var container = document.createElement('div');
          var wrap = document.createElement('ul');
          section.className = 'section01 swiper-slide p-topic__slider__vertical__slide';
          container.className = 'Hswiper-container p-topic__slider__horizon js-fullScreenFlexSwiper__horizon';
          wrap.className = 'swiper-wrapper p-topic__slider__horizon__inner';
          wrap.innerHTML = li;
          container.appendChild(wrap);
          section.appendChild(container); // console.log(section)

          $('.p-topic__slider__vertical__inner').append(section);
        });
        $('.js-fullScreenFlexSwiper__horizon').append(navHorizon);
      } else {
        console.log('"MV" data is nothing'); // if(removeEle != null){ $(removeEle).remove() };
      }

      resolve();
    }).then(function () {
      // console.log('JSON処理完了')
      var verticalSlider = new Swiper(".js-fullScreenFlexSwiper__vertical", {
        loop: false,
        direction: "vertical",
        slidesPerView: 1,
        speed: 800,
        mousewheel: {
          forceToAxis: true,
          invert: true,
          releaseOnEdges: true,
          eventsTarget: '#visualPanels'
        },
        pagination: {
          el: ".Vswiper-pagination",
          type: "bullets",
          clickable: true
        },
        on: {
          afterInit: function afterInit() {
            swiper.emit();
          },
          toEdge: function toEdge() {
            setTimeout(function () {
              console.log('ok');
              verticalSlider.params.mousewheel.releaseOnEdges = true;
              verticalSlider.params.touchReleaseOnEdges = true; //verticalSlider.mousewheel.disable();

              console.log('disabled');
            }, 200);
          },
          fromEdge: function fromEdge() {
            setTimeout(function () {
              verticalSlider.params.mousewheel.releaseOnEdges = false;
              verticalSlider.params.touchReleaseOnEdges = false; //verticalSlider.mousewheel.enable();

              console.log('enabled');
            }, 200);
          }
        }
      });
      /* 水平スライダーの設定 */

      var horizontalSlider = new Swiper(".js-fullScreenFlexSwiper__horizon", {
        loop: false,
        direction: "horizontal",
        slidesPerView: 1,
        speed: 800,
        //nested:true,
        mousewheel: {
          forceToAxis: true
        },
        pagination: {
          el: ".swiper-pagination",
          //type: "bullets",
          type: "fraction",
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }); //スムーススクロール（高さ）

      $(function () {
        // #で始まるリンクをクリックした場合
        $('.js-moveToNext').click(function () {
          // スクロールの速度
          var speed = 400; // スクロールタイプ

          var type = 'swing'; // href属性の取得

          var href = $(this).attr("href"); // 移動先の取得（hrefが#indexならトップ$(html)に、）

          var target = $(href == "#index" ? 'html' : href); // 移動先のポジション取得
          //let position = target.offset().top;

          var viewHeight = $(window).innerHeight(); //console.log("viewHeight:"+viewHeight);
          // animateでスムーススクロール   

          $('body,html').animate({
            scrollTop: viewHeight
          }, speed, type);
          return false;
        });
      });
    }).catch(function () {
      console.error();
    });
  }
});
$(window).on('load resize', function () {
  var swiper;
  var w = $(window).width(); //console.log('w:'+w);

  if (w <= 767) {
    if (swiper) {
      return;
    } else {
      swiper = new Swiper('.js-quickNavSwiper', {
        slidesPerView: 4,
        loop: true,
        //autoHeight: true,
        slidesPerGroup: 1,
        updateOnImagesReady: true,
        preloadImages: true,
        loopAdditionalSlides: 1,
        navigation: {
          nextEl: '.p-topQuicknav .swiper-button-next',
          prevEl: '.p-topQuicknav .swiper-button-prev'
        }
      }); //console.log('OK w:'+w);
    }
  } else {
    if (swiper) {
      swiper.destroy();
      swiper = undefined;
    }
  }
});
$(function () {
  var ele = '.p-topPick';
  $(ele).each(function (e) {
    var topPickSwiper = new Swiper(ele + ' .js-topPickSwiper', {
      slidesPerView: 1,
      updateOnImagesReady: true,
      preloadImages: true,
      loopAdditionalSlides: 1,
      //loop:true,
      SimulateTouch: true,
      touchEventsTarget: true,
      // autoplay:{
      // 	speed:5000,
      // },
      navigation: {
        nextEl: ele + ' .swiper-button-next',
        prevEl: ele + ' .swiper-button-prev'
      },
      pagination: {
        el: ele + " .swiper-pagination",
        //type: "bullets",
        type: "fraction",
        clickable: true
      }
    });
  });
});
$(function () {
  var ele = '.p-sec01';
  $(ele).each(function (e) {
    var _Swiper;

    var topPickSwiper = new Swiper(ele + ' .js-itemListSwiper', (_Swiper = {
      //slidesPerView:5,
      updateOnImagesReady: true,
      preloadImages: true,
      loopAdditionalSlides: 1,
      slidesPerView: 'auto',
      spaceBetween: 20,
      freeMode: true,
      freeModeSticky: true,
      //touchRatio: .03,
      grabCursor: true
    }, _defineProperty(_Swiper, "spaceBetween", 20), _defineProperty(_Swiper, "navigation", {
      nextEl: ele + ' .swiper-button-next',
      prevEl: ele + ' .swiper-button-prev'
    }), _defineProperty(_Swiper, "breakpoints", {
      500: {
        //slidesPerView: 3,
        spaceBetween: 10
      }
    }), _Swiper));
  });
});
randomList('#newarrival .p-sec01__list'); //INSTAGRAMのAPIを利用して一覧作成

$(function () {
  var graphapi_url = 'https://graph.facebook.com/v13.0/';
  var count = '10'; //画像取得数

  var type = 'top_media'; //人気の投稿：top_media、最新の投稿：recent_media

  var id = '17841405292069831'; //InstagramビジネスアカウントID

  var token = 'EAAOkFTNDtpMBAMdbkLzuduZAeqNgNo844IcUIsjnGdfalPAcDekj6ZBFmrryMSZARz754v6kD9uPoQ5qNNbZCrPZBaRUe431BTWybQOguo3hP29MVla0HMFOM5EhLeuaYLal9Dhh2pamLu9qlHDQjcnrGwajbXCXyzGL2radmTdBr2xPPyVSJj3Woufju67AZD'; // アクセストークン3

  var fields = 'caption,children{id,media_type,media_url},comments_count,id,like_count,media_type,media_url,permalink,timestamp';
  /***検索するハッシュタグを元にハッシュタグIDを取得***/

  var query = 'your_KLON'; //検索するハッシュタグを入力

  var query_index = graphapi_url + '/ig_hashtag_search?user_id=' + id + '&q=' + query + '&access_token=' + token + '&jsonp=callback';
  var hashtag_id = ''; //ハッシュタグID
  //ハッシュタグIDを取得してくる

  fetch(query_index).then(function (response) {
    return response.text();
  }).then(function (text) {
    //処理
    var obj = JSON.parse(text);
    obj = obj.data;
    $.each(obj, function (key, val) {
      hashtag_id = val.id;
    });
    requestInstagramAPI();
  }); //APIを元に一覧を生成

  function requestInstagramAPI() {
    ajaxLoadingUp('#instagram');
    $.ajax({
      type: 'GET',
      timeout: 10000,
      //url: 'https://graph.facebook.com/v6.0/'+id+'?fields=name%2Cmedia.limit('+count+')%7Bcaption%2Cmedia_url%2Cthumbnail_url%2Cpermalink%7D&access_token='+token,//自分のアカウントの投稿取得用
      url: graphapi_url + hashtag_id + '/' + type + '?user_id=' + id + '&fields=' + fields + '&access_token=' + token + '&jsonp=callback',
      //ハッシュタグ検索取得用
      dataType: 'jsonp',
      jsonpCallback: 'callback'
    }).done(function (json) {
      //console.dir(json);
      var mediaData = json.data; //自アカウント取得の場合はjson.media.data

      var html = ''; //console.dir(mediaData);

      $.each(mediaData, function (i, row) {
        if (i < count) {
          var id = row.id;
          var caption = row.caption;
          var type = row.media_type;
          var data = row.timestamp;
          var like = row.like_count;
          var comment = row.comment_count;
          var image = row.media_url;
          var link = row.permalink;

          if (row.children) {
            var children = row.children.data;
          }

          if (type == 'VIDEO') {
            html += '<li><figure class="p-topSNS__list__visual"><a href="' + link + '" target="_blank"><video src="' + image + '" autoplay muted loop></video></a></figure></li>';
          } else if (type == 'IMAGE') {
            html += '<li><figure class="p-topSNS__list__visual"><a href="' + link + '" target="_blank"><img src="' + image + '" alt="' + caption + '"></a></figure></li>';
          } else if (type == 'CAROUSEL_ALBUM') {
            $.each(children, function (e, child) {
              if (e < 1) {
                var child_id = child.id;
                var child_type = child.media_type;
                var child_image = child.media_url;

                if (child_type == 'VIDEO') {
                  html += '<li><figure class="p-topSNS__list__visual"><a href="' + link + '" target="_blank"><video src="' + child_image + '" autoplay muted loop></video></a></figure></li>'; //html += '<li><video class="p-topSNS__list__visual"><a href="'+link+'" target="_blank"><source src="'+image+'" type="video/ogg"><source src="'+image+'" type="video/mp4"></a></video></li>';  
                } else if (child_type == 'IMAGE') {
                  html += '<li><figure class="p-topSNS__list__visual"><a href="' + link + '" target="_blank"><img src="' + child_image + '" alt="' + caption + '"></a></figure></li>';
                }
              }
            });
          }
        }
      });
      $('.js-loadAPI__instagram').html(html);
      loadingDown('#instagram');
    }).fail(function (json) {
      console.error('取得できませんでした');
      console.log('https://graph.facebook.com/' + query + '/recent_media?user_id=' + id + '&fields=id,media_type,comments_count,like_count,timestamp,thumbnail_url,media_url&access_token=' + token);
      $('#instagram').remove();
    });
  }
}); //snap coordinate
// $(function(){
//     ajaxLoadingUp('.l-snap');
//   $.ajax({
//       type: 'GET',
//       url: 'https://klonklonklon.com/apps/note/wp-json/wp/v2/snap?_embed&per_page=4&?orderby=date&order=desc',
//       dataType: 'json'
//   }).done(function(json){
//       console.dir(json);
//     var html = '';
//     //記事の件数分イテレートする
//     $.each(json, function( i, row ) {
//       var snap_id = row.custom.snap_id;
//       var snap_url = row.custom.snap_url;
//       var snap_thumbnail = row.custom.snap_thumbnail;//返り値はimgタグを含む
//       var user_url = row.custom.user_url;
//       var user_avatar = row.custom.user_avatar;//返り値はimgタグを含む
//       var user_name = row.custom.user_name;
//       var shop_name = row.custom.shop_name;
//       var shop_url = row.custom.shop_url;
//       html += '<li>';
//       html += '<figure class="p-topSnap__list__visual"><a href="'+snap_url+'">'+snap_thumbnail+'</a></figure>';
//       html += '<div class="p-topSnap__list__bio">';
//       html += '<figure class="p-topSnap__list__bio__avatar"><a href="'+user_url+'">'+user_avatar+'</a></figure>';
//       html += '<div class="p-topSnap__list__bio__block">';
//       html += '<p class="p-topSnap__list__bio__block__shop"><a href="'+shop_url+'" class="c-fs__underline">'+shop_name+'</a></p>';
//       html += '<p  class="p-topSnap__list__bio__block__name">'+user_name+'</p>';
//       html += '</div>';
//       html += '</li>';
//     });
//     //整形した記事の情報をページに追加する
//     $('.js-ajax__wp-snap').append(html);
//     loadingDown('.l-snap');
//   }).fail(function(json){
//       console.error('WordPressのSNAP記事取得に失敗しました。[SNAP]');
//       $('.l-snap').remove();
//   });
// });
//////////////TOPランキングの処理

$(function () {
  //GASのAPIのURL（各自変更してください。）
  var endpoint = "https://script.google.com/macros/s/AKfycbxLSqXnCcvRztpy_YA5wBw22mRuKu3HaxKQoLaQPaywzE-ln7I-ecAB9CBV9Mz9mrpiNg/exec"; //APIを使って非同期データを取得する

  fetch(endpoint).then(function (response) {
    return response.json();
  })
  /*成功した処理*/
  .then(function (data) {
    //JSONから配列に変換
    var object = data;
    var today = new Date().getTime();
    var ranking_box = document.querySelectorAll('.js-ajax__ss-ranking_top');

    for (var s = 0; s < ranking_box.length; s++) {
      var group = object;
      var html_li = '';
      var counter = 0;
      var display_limit = 5;

      for (var i = 0; i <= object.length; i++) {
        if (counter < display_limit) {
          var id = group[i].id;
          var name = group[i].name;
          var price = group[i].price.toLocaleString();
          var sale_price = group[i].sale_price.toLocaleString();
          var stock = group[i].stock;
          var display = group[i].display;
          var start_date = group[i].start_date;
          var end_date = group[i].end_date;
          var display_time_flg = false;

          if (start_date == null && end_date == null || today >= start_date && today < end_date || today >= start_date && end_date == null || start_date == null && today < end_date) {
            display_time_flg = true;
          }

          if (display == 'showing' && display_time_flg) {
            html_li += '<li>';
            html_li += '<figure class="p-sec01__list__visual"><a href="//klonklonklon.com/?pid=' + id + '"><img src="//img21.shop-pro.jp/PA01387/268/product/' + id + '.jpg" alt="' + name + '"></a></figure>';
            html_li += '<dl class="p-sec01__list__block">';
            html_li += '<dt class="p-sec01__list__name"><a href="//klonklonklon.com/?pid=' + id + '">' + name + '</a></dt>';
            html_li += '<dd class="p-sec01__list__price">';

            if (stock == 0) {
              html_li += '<span class="c-fc__red">SOLD OUT</span>';
            } else if (price != sale_price) {
              html_li += '<span class="c-fs__linethrough">&yen' + price + '(tax in)</span><span class="c-fc__red">&yen' + sale_price + '(tax in)</span>';
            } else {
              html_li += '&yen' + price + '(tax in)';
            }

            html_li += '</dd>';
            html_li += '</dl>';
            html_li += '</li>';
            counter++;
          }
        } else {
          console.log('reach end');
          break;
        }
      } // console.log(html_li);


      ranking_box[s].innerHTML += html_li;
    }
  });
});